import { useCallback, useEffect, useState } from 'react'

export const useScrollHandle = (): { isScrollDown: boolean } => {
  const [isScrollDown, setIsScrollDown] = useState<boolean>(false)
  const [lastYPos, setLastYPos] = useState<number>(0)

  const handleScroll = useCallback(() => {
    const currWindowPos = window.pageYOffset

    if (currWindowPos >= lastYPos && currWindowPos > 190) {
      //scroll down
      setIsScrollDown(true)
    } else if (currWindowPos <= 190) {
      //scroll up
      setIsScrollDown(false)
    }
    currWindowPos !== lastYPos && setLastYPos(currWindowPos)
  }, [lastYPos])

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll)
    }
  }, [handleScroll])
  return { isScrollDown }
}
