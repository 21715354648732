import { FC } from 'react'
import ChevronRightIcon from '../../../components/ui/icon/ChevronRightIcon'
import { Link } from '../../../link'
import { JobPublicType } from '../job-detail.types'

const Breadcrumb: FC<{ job?: JobPublicType }> = ({ job }) => {
  return (
    <div className="flex items-center mb-4">
      <a
        href={Link.JOB_LIST}
        className="text-sm text-[#374151] text-medium mr-4">
        Jobs
      </a>
      <span className="mr-4">
        <ChevronRightIcon />
      </span>
      <a
        href={
          job?.slug && job?.id
            ? Link.LINK_URL_JOB_DETAILS(job?.slug, job?.id)
            : '#'
        }
        className="text-sm text-[#6b7280] text-medium">
        {job?.title}
      </a>
    </div>
  )
}

export default Breadcrumb
