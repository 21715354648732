import { FC } from 'react'
import FacebookIcon from '../../../components/ui/icon/FacebookIcon'
import LinkedinIcon from '../../../components/ui/icon/LinkedinIcon'
import LinkIcon from '../../../components/ui/icon/LinkIcon'
import { Button } from '../../../core/components/Button'
import { Input } from '../../../core/components/FormControl/Input'
import { JobPublicType } from '../job-detail.types'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'
import { Link } from '../../../link'
import ENV from '../../../env'

const ShareJob: FC<{ job?: JobPublicType }> = ({ job }) => {
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
  }
  return (
    <div className="p-4 w-full border border-[#ebedf0] rounded-lg">
      <div className="text-[20px] leading-[30px] text-[#111827] font-[600] mb-2.5">
        Share
      </div>
      <div className="text-[#374151] font-medium mb-2">Copy link</div>
      <div className="flex justify-between mb-6">
        <div className="flex-1 mr-2">
          <Input
            name="job_link"
            disabled={true}
            value={
              job?.slug &&
              job?.id &&
              `${ENV.PUBLIC_REMOTE_JOB_URL}${Link.LINK_URL_JOB_DETAILS(
                job?.slug,
                job?.id
              )}`
            }
          />
        </div>

        <Button
          className="hidden sm:inline-flex"
          type="secondary"
          icon={<LinkIcon />}
          title="Copy"
          onClick={() =>
            job?.slug &&
            job?.id &&
            copyToClipboard(
              `${ENV.PUBLIC_REMOTE_JOB_URL}${Link.LINK_URL_JOB_DETAILS(
                job?.slug,
                job?.id
              )}`
            )
          }
        />
        <Button
          className="sm:hidden"
          type="secondary"
          icon={<LinkIcon size={20} />}
          onClick={() =>
            job?.slug &&
            job?.id &&
            copyToClipboard(
              `${ENV.PUBLIC_REMOTE_JOB_URL}${Link.LINK_URL_JOB_DETAILS(
                job?.slug,
                job?.id
              )}`
            )
          }
        />
      </div>
      <div className="text-[#374151] font-medium mb-2">Share on Social</div>
      <div className="flex">
        <FacebookShareButton
          url={
            job?.slug && job?.id
              ? `${ENV.PUBLIC_REMOTE_JOB_URL}${Link.LINK_URL_JOB_DETAILS(
                  job?.slug,
                  job?.id
                )}`
              : ''
          } //eg. https://www.example.com
          //   quotes={quotes} //"Your Quotes"
          //   hashtag={hashtag} // #hashTag
        >
          <a className="inline-block w-[42px] h-[42px] rounded-full border border-[#d1d5db] flex items-center justify-center mr-3">
            <FacebookIcon size={20} color="#6b7280" />
          </a>
        </FacebookShareButton>
        <LinkedinShareButton
          title={job?.title}
          source={job?.company_logo}
          url={
            job?.slug && job?.id
              ? `${ENV.PUBLIC_REMOTE_JOB_URL}${Link.LINK_URL_JOB_DETAILS(
                  job?.slug,
                  job?.id
                )}`
              : ''
          }>
          <a className="inline-block w-[42px] h-[42px] rounded-full border border-[#d1d5db] flex items-center justify-center mr-3">
            <LinkedinIcon size={20} color="#6b7280" />
          </a>
        </LinkedinShareButton>
      </div>
    </div>
  )
}

export default ShareJob
