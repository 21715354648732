import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import BriefCaseIcon from '../../components/ui/icon/BriefCaseIcon'
import DollarIcon from '../../components/ui/icon/DollarIcon'
import MarkIcon from '../../components/ui/icon/MarkIcon'
import PaperAirPlane from '../../components/ui/icon/PaperAirPlane'
import StartIcon from '../../components/ui/icon/StartIcon'
import UserIcon from '../../components/ui/icon/UserIcon'
// import { useApiClient } from '../../core/api/client'
import { Button } from '../../core/components/Button'
import Breadcrumb from './component/Breadcrumb'
import JobDetailInfoCol from './component/JobDetailInfoCol'
import { JobPublicType, LocationType } from './job-detail.types'
import { Link } from '../../link'
import { listJobTypeFixture } from '../job-list/component/JobItem'
import ViewSalary from '../../components/ViewSalary'
import { TIME_FORMAT } from '../../core/utilities/common'
import dayjs from 'dayjs'
import ShareJob from './component/ShareJob'
import Image from 'next/image'
import PublicPageMeta from '../../components/meta/PublicPageMeta'
import ENV from '../../env'
import useConvertSalary from '../../hooks/useConvertSalary'
import { useTranslation } from 'react-i18next'
import { GoogleStructureTag } from './component/GoogleStructureTag'
import { useScrollHandle } from './hooks/useScrollHandle'
import LogoCompanyView from '../job-list/component/LogoCompany'

const useToSalary = () => {
  const { convertSalary } = useConvertSalary()
  const toSalaryString = ({
    min_salary,
    max_salary,
    salary_currency,
    negotiable,
    hide_salary,
    salary_type
  }: {
    min_salary?: number
    max_salary?: number
    salary_currency?: string
    negotiable?: boolean
    hide_salary?: boolean
    salary_type?: string
  }) => {
    if (!salary_type || salary_type === 'negotiable' || hide_salary)
      return 'Negotiable'

    if (salary_type === 'range')
      return `${convertSalary(min_salary, true)} - ${convertSalary(
        max_salary
      )} ${salary_currency}`

    if (salary_type === 'upto')
      return `Up to ${convertSalary(max_salary || 0)} ${salary_currency}`
    if (salary_type === 'from_min')
      return `From ${convertSalary(min_salary || 0)} ${salary_currency}`
    return ''
  }
  return toSalaryString
}
export const uniqueAndJoinLocation = (locations: string[] = []) => {
  return locations
    .filter((location, index) => locations.indexOf(location) === index)
    .join(', ')
}
const JobDetail: FC<{ job?: JobPublicType }> = ({ job }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const toSalary = useToSalary()
  const { isScrollDown } = useScrollHandle()

  return !job ? null : (
    <>
      <PublicPageMeta
        title={t('meta:detail.title', {
          job_title: job?.title,
          company_name: job?.company_name
        })}
        description={t('meta:detail.description', {
          job_title: job?.title,
          company_name: job?.company_name,
          location: uniqueAndJoinLocation(
            job?.job_locations.data?.map(
              (job_location: any) =>
                job_location.attributes.location.data.attributes.state
            )
          ),
          salary: toSalary({
            min_salary: job?.min_salary,
            max_salary: job?.max_salary,
            negotiable: job?.negotiable,
            hide_salary: job?.hide_salary,
            salary_currency: job?.salary_currency,
            salary_type: job?.salary_type
          })
        })}
        url={`${ENV.PUBLIC_REMOTE_JOB_URL}${Link.LINK_URL_JOB_DETAILS(
          job?.slug,
          job?.id
        )}`}
        keywords={`${job?.company_name} is hiring ${job?.title}, ${job?.title} job at ${job?.company_name}`}
        // image={job?.company_logo}
        alternateLink={{
          url: `${ENV.PUBLIC_REMOTE_JOB_URL}${Link.LINK_URL_JOB_DETAILS(
            job?.slug,
            job?.id
          )}`,
          lang: locale || 'vi'
        }}
      />

      {job && <GoogleStructureTag jobDetail={job} />}
      <Header classCustom="border-b-[1px] border-[#ebedf0] sticky top-0 bg-white " />
      <div
        className={`${
          !isScrollDown ? `opacity-0 invisible` : ''
        } fixed top-[71px] left-0 w-full bg-white border-b-[1px] border-[#e5e7eb] z-10 pt-[17px] 
        pb-[15px]`}>
        <div className="lg:max-w-[1200px] m-auto hidden md:flex justify-between items-center">
          <div className="flex items-start">
            <div className="w-16 h-16 flex items-center border border-[#ebedf0] rounded-md bg-white mr-4 overflow-hidden">
              <LogoCompanyView
                title={job?.company_name}
                src={job?.company?.data?.attributes?.logo_url}
                alt={job?.company_name}
                size={'100%'}
              />
            </div>
            <div>
              <div className="flex items-center">
                <div className="text-[24px] leading-[36px] text-[#141921] font-extrabold mb-1.5">
                  {job?.title}
                </div>
                {job?.hot_job && (
                  <div className="uppercase text-white text-xs font-bold bg-[#ff4d4f] rounded-[20px] flex items-center py-0.5 px-1.5 ml-3">
                    <span className="mr-0.5">HOT</span>
                    <StartIcon />
                  </div>
                )}
              </div>
              <div className="text-base text-[#374151] text-medium">
                {job?.company?.data?.attributes?.name}
              </div>
            </div>
          </div>
          <Button
            icon={<PaperAirPlane className="-rotate-45" />}
            className="hidden lg:inline-flex"
            onClick={() =>
              job?.slug &&
              job?.id &&
              (window.open(
                `${Link.CANDIDATE_APPLY_JOB(
                  job?.slug,
                  job?.id
                )}?openModalApply=true`
              ),
              '_blank')
            }
            title="Apply now"
            size="large-xl"
          />
        </div>
      </div>
      <div className="px-4 lg:px-0 lg:max-w-[1200px] m-auto">
        <div className="pt-4 pb-[23px] border-b-[1px] border-[#ebedf0]">
          <Breadcrumb job={job} />
          <div className="flex justify-between items-start mb-2 md:mb-[10px]">
            <div className="flex items-start">
              <div className=" w-16 h-16 flex items-center border border-[#ebedf0] rounded-md bg-white mr-4 overflow-hidden">
                <LogoCompanyView
                  title={job?.company_name}
                  src={job?.company?.data?.attributes?.logo_url}
                  alt={job?.company_name}
                  size={'100%'}
                />
              </div>
              <div className="flex-1">
                <div className="flex items-start sm:items-center">
                  <h1 className="text-[20px] md:text-[24px] leading-[24px] md:leading-[36px] text-[#141921] font-extrabold mb-1.5">
                    {job?.title}
                  </h1>
                  {job?.hot_job && (
                    <div className="uppercase text-white text-[12px] leading-[16px] sm:text-xs font-bold bg-[#ff4d4f] rounded-[20px] flex items-center py-0.5 px-1.5 ml-3">
                      <span className="mr-0.5">HOT</span>
                      <StartIcon size={14} />
                    </div>
                  )}
                </div>

                <div className="hidden md:block text-base text-[#374151] font-medium">
                  {job?.company?.data?.attributes?.name}{' '}
                  {job?.published_date && (
                    <span className="text-[#6b7280] font-normal">
                      &bull; {dayjs(job?.published_date).format(TIME_FORMAT)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <Button
              onClick={() =>
                job?.slug &&
                job?.id &&
                (window.open(
                  `${Link.CANDIDATE_APPLY_JOB(
                    job?.slug,
                    job?.id
                  )}?openModalApply=true`
                ),
                '_blank')
              }
              icon={<PaperAirPlane className="-rotate-45" />}
              className="hidden lg:inline-flex"
              title="Apply now"
              size="large-xl"
            />
          </div>
          <div className="md:hidden text-base text-[#374151] text-medium mb-2">
            {job?.company?.data?.attributes?.name}
            <br />
            {job?.published_date && (
              <>
                <span className="hidden md:inline-block text-[#6b7280] mx-1.5">
                  &bull;
                </span>
                <span className="mt-1 md:mt-0 text-sm md:text-base text-[#6b7280]">
                  {dayjs(job?.published_date).format(TIME_FORMAT)}
                </span>
              </>
            )}
          </div>
          <div className="md:flex md:items-center">
            <div className="flex items-center mr-6 mt-1.5 md:mt-0">
              <BriefCaseIcon color={'#9ca3af'} size={16} />
              <span className="text-sm text-[#7a7c82] ml-1.5">
                {
                  listJobTypeFixture.filter(
                    (job_type) => job_type?.id === String(job?.employment_type)
                  )[0]?.name
                }
              </span>
            </div>
            {(job?.job_locations.data?.length || 0) > 0 && (
              <h2 className="flex items-center mr-6 mt-1.5 md:mt-0">
                <MarkIcon color={'#9ca3af'} size={16} />
                <span className="text-sm text-[#7a7c82] ml-1.5">
                  {uniqueAndJoinLocation(
                    job?.job_locations.data?.map(
                      (job_location: any) =>
                        job_location.attributes.location.data.attributes.state
                    )
                  )}
                </span>
              </h2>
            )}
            <div className="flex items-center mr-6 mt-1.5 md:mt-0">
              <DollarIcon color={'#9ca3af'} size={16} />
              <span className="text-sm text-[#7a7c82] ml-1.5">
                <ViewSalary
                  hide_salary={false}
                  min_salary={job?.min_salary}
                  max_salary={job?.max_salary}
                  salary_currency={job?.salary_currency}
                  salary_type={job?.salary_type}
                />
              </span>
            </div>
            <div className="flex items-center mr-6 mt-1.5 md:mt-0">
              <UserIcon color={'#9ca3af'} size={16} />
              <span className="text-sm text-[#7a7c82] ml-1.5">
                {job?.head_count}
              </span>
            </div>
            {(job?.years_of_experience || job?.years_of_experience === 0) && (
              <div className="flex items-center mr-6 mt-1.5 md:mt-0">
                <StartIcon color={'#9ca3af'} size={16} />
                <span className="text-sm text-[#7a7c82] ml-1.5">
                  {job?.years_of_experience === 0
                    ? 'Under 1 year'
                    : job?.years_of_experience > 10
                    ? 'Over 10 years'
                    : `${job?.years_of_experience} years of experience`}
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-wrap">
            {job?.job_skills_attribute.data &&
              job?.job_skills_attribute?.data.map((skill) => (
                <div
                  key={`skill-${skill.id}`}
                  className="bg-[#f3f4f6] py-1 sm:py-[5px] px-2 rounded-md mr-2 mt-3 text-sm text-[#374151]">
                  {skill.attributes.name}
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:justify-between pt-6 pb-16">
          <div className="md:w-[61%]">
            <JobDetailInfoCol job={job} />
          </div>
          <div className="md:w-[32%] hidden md:block">
            <ShareJob job={job} />
          </div>
        </div>
      </div>

      <div className="md:hidden bg-white sticky bottom-0 p-4 border-t-[1px] border-[#e5e7eb]">
        <Button
          className="w-full"
          icon={<PaperAirPlane />}
          title={'Apply now'}
          size="large"
          onClick={() =>
            job?.slug &&
            job?.id &&
            (window.open(
              `${Link.CANDIDATE_APPLY_JOB(
                job?.slug,
                job?.id
              )}?openModalApply=true`
            ),
            '_blank')
          }
        />
      </div>
      <Footer />
    </>
  )
}

export default JobDetail
