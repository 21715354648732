import { FC, useState } from 'react'
import { isHTML } from '../../core/utilities/common'

const CollapseText: FC<{
  text: string
  maxLines?: number
  textClassName?: string
  viewButtonClassName?: string
}> = ({ text, maxLines = 3, textClassName = '', viewButtonClassName = '' }) => {
  const [isShowing, setIsShowing] = useState<boolean>(false)
  return (
    <>
      <style jsx>{`
        .line-camp-1 {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .line-camp-2 {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .line-camp-3 {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .line-camp-4 {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .line-camp-5 {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      `}</style>
      <div
        className={`line-camp-${
          !isShowing ? maxLines : ''
        } text-[#374151] ${textClassName}`}>
        {isHTML(text) ? (
          <div dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          text
        )}
      </div>
      {!isShowing && (
        <a
          className={`text-[#1890ff] font-medium ${viewButtonClassName}`}
          onClick={() => setIsShowing(!isShowing)}>
          View more
        </a>
      )}
    </>
  )
}

export default CollapseText
