import { FC } from 'react'
import useConvertSalary from '../../hooks/useConvertSalary'

const ViewSalary: FC<{
  min_salary?: number
  max_salary?: number
  salary_currency?: number | string
  // negotiable: boolean
  hide_salary?: boolean
  fallbackLink?: string
  salary_type?: string
}> = ({
  min_salary,
  max_salary,
  salary_currency,
  // negotiable,
  hide_salary,
  salary_type
}) => {
  const { convertSalary } = useConvertSalary()

  if (!salary_type || salary_type === 'negotiable' || hide_salary)
    return <div className="text-salary">Negotiable</div>
  if (salary_type === 'range')
    return (
      <div className="text-salary">
        {convertSalary(min_salary, true)} - {convertSalary(max_salary)}{' '}
        {salary_currency}
      </div>
    )
  if (salary_type === 'upto')
    return (
      <div className="text-salary">
        Up to {convertSalary(max_salary || 0)} {salary_currency}
      </div>
    )
  if (salary_type === 'from_min')
    return (
      <div className="text-salary">
        From {convertSalary(min_salary || 0)} {salary_currency}
      </div>
    )
  return <div />
}

export default ViewSalary
