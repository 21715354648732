import { FC } from 'react'
import CollapseText from '../../../components/CollapseText'
import ChevronRightIcon from '../../../components/ui/icon/ChevronRightIcon'
import FacebookSocialIcon from '../../../components/ui/icon/FacebookSocialIcon'
import GlobeIcon from '../../../components/ui/icon/GlobeIcon'
import GroupUserIcon from '../../../components/ui/icon/GroupUserIcon'
import MarkIcon from '../../../components/ui/icon/MarkIcon'
import YoutubeSocialIcon from '../../../components/ui/icon/YoutubeSocialIcon'
import { JobPublicType } from '../job-detail.types'
import { Link } from '../../../link'
import { isHTML } from '../../../core/utilities/common'
import ShareJob from './ShareJob'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import LogoCompanyView from '../../job-list/component/LogoCompany'

const JobDetailGallery = dynamic(() => import('./JobDetailGallery'))

const CompanyInfo: FC<{ job: JobPublicType }> = ({ job }) => {
  return (
    <>
      <div className="flex items-center mb-2">
        <MarkIcon color={'#9ca3af'} size={16} />
        <span className="text-sm text-[#374151] ml-1.5">
          {job?.company?.data?.attributes?.location?.data?.attributes?.state}
        </span>
      </div>
      <div className="flex items-center mb-2">
        <GroupUserIcon color={'#9ca3af'} size={16} />
        <span className="text-sm text-[#374151] ml-1.5">
          {job?.company?.data?.attributes?.company_size} employees
        </span>
      </div>
      <div className="flex items-center">
        {job?.company?.data?.attributes?.website_url && (
          <>
            <GlobeIcon color={'#9ca3af'} size={16} />
            <a
              href={job?.company?.data?.attributes?.website_url}
              target="blank"
              className="text-sm text-[#374151] ml-1.5">
              {job?.company?.data?.attributes?.website_url}
            </a>
          </>
        )}

        {(job?.company?.data?.attributes?.facebook_url ||
          job?.company?.data?.attributes?.youtube_url) && (
          <div className="ml-4 border-l-[1px] border-[#ebedf0] pl-4 flex items-center">
            <a
              href={job?.company?.data?.attributes?.facebook_url}
              className="mr-4">
              <FacebookSocialIcon />
            </a>
            <a
              href={job?.company?.data?.attributes?.youtube_url}
              className="mr-4">
              <YoutubeSocialIcon />
            </a>
          </div>
        )}
      </div>
      <div className="flex flex-wrap">
        {job?.company?.data?.attributes?.industries?.data?.map((ind) => (
          <div
            key={`industry-${ind.id}`}
            className="bg-[#f6f8fa] py-1 sm:py-[5px] px-2 rounded-md mr-2 mt-3 text-sm text-[#374151]">
            {ind.attributes?.name}
          </div>
        ))}
      </div>
    </>
  )
}
const JobDetailInfoCol: FC<{ job?: JobPublicType }> = ({ job }) => {
  return (
    <div>
      {job?.description && isHTML(job?.description) ? (
        <div
          className="text-[#374151] pb-8 border-b-[1px] border-[#ebedf0]"
          dangerouslySetInnerHTML={{ __html: job?.description }}
        />
      ) : (
        <div className="text-[#374151] pb-8 border-b-[1px] border-[#ebedf0]">
          {job?.description}
        </div>
      )}
      <div className="md:hidden mb-6">
        <ShareJob job={job} />
      </div>
      <div className="md:p-6 md:border md:border-[#ebedf0] rounded-lg">
        <div className="flex justify-between mb-[21px]">
          <div className="text-[20px] leading-[30px] text-[#111827] font-[600]">
            Company Info
          </div>
          {job?.company_slug && job?.company_id && (
            <a
              href={Link.CANDIDATE_COMPANY_PUBLISH_JOBS(
                job?.company_slug,
                job?.company_id
              )}
              target="_blank"
              className="text-sm text-[#1890ff] font-medium flex items-center"
              rel="noreferrer">
              <span className="mr-1.5">See more</span>
              <ChevronRightIcon size={18} color={'#1890ff'} />
            </a>
          )}
        </div>

        <div className="border-b-[1px] border-[#ebedf0] pb-[15px]">
          <div className="flex">
            <div className="w-12 h-12 sm:w-16 sm:h-16 flex items-center border border-[#ebedf0] rounded-md bg-white overflow-hidden mr-4">
              <LogoCompanyView
                size="100%"
                title={job?.company_name}
                src={job?.company?.data?.attributes?.logo_url}
                alt={job?.company_name}
              />
            </div>
            <div className="flex-1">
              <div className="text-base text-[#374151] font-[600] mb-2">
                {job?.company_name}
              </div>

              <div className="hidden sm:block">
                {job && <CompanyInfo job={job} />}
              </div>
            </div>
          </div>
          {job && (
            <div className="mt-4 sm:hidden">
              <CompanyInfo job={job} />
            </div>
          )}
        </div>

        <div className="pt-4">
          {job?.company?.data?.attributes?.about && (
            <div className="">
              <div className="text-[#111827] font-medium mb-1.5">About</div>
              <CollapseText text={job?.company?.data?.attributes?.about} />
            </div>
          )}
          {job?.company?.data?.attributes?.office_address && (
            <div className="mt-4">
              <div className="text-[#111827] font-medium mb-1.5">
                Working Address
              </div>
              <div className="text-[#374151]">
                {job?.company?.data?.attributes?.office_address}
              </div>
              <a
                target="_blank"
                className={`text-[#1890ff] font-medium`}
                href={`https://maps.google.com/maps?q=${encodeURIComponent(
                  job?.company?.data?.attributes?.office_address
                )}&t=&z=13&ie=UTF8 `}
                rel="noreferrer">
                View map
              </a>
            </div>
          )}
          {job?.company?.data?.attributes?.galleries?.data &&
            job?.company?.data?.attributes?.galleries?.data?.length > 0 && (
              <div className="relative mt-4">
                <div className="text-[#111827] font-medium mb-1.5">Gallery</div>
                <JobDetailGallery
                  galleries={job?.company?.data?.attributes?.galleries?.data?.map(
                    (item) => ({ id: item.id, src: item?.attributes?.image })
                  )}
                />
                <div className="swiper-pagination swiper__gallery-pagination bottom-[-30px]"></div>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default JobDetailInfoCol
