import { FC } from 'react'
const ChevronRightIcon: FC<{ color?: string; size?: number }> = ({
  color = '#9CA3AF',
  size = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.293 14.707a1 1 0 0 1 0-1.414L10.586 10 7.293 6.707a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z"
        fill={color}
      />
    </svg>
  )
}

export default ChevronRightIcon
