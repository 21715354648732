import { withCache } from '../core/utilities/ssr'
import JobDetail from '../modules/job-detail'
import { getJobDetail } from './api/job'

export const getServerSideProps = withCache<
  React.ComponentProps<typeof JobDetail>
>(async ({ query }) => {
  // Fetch data from external API
  const jobPatternMatch = query?.slug?.toString().match(`^.+i([0-9]+)$`)
  if (jobPatternMatch) {
    const jobId = jobPatternMatch[1]

    const res = await getJobDetail(jobId)

    // Pass data to the page via props
    return { props: { job: res.data?.data?.attributes } }
  }
  return { props: { error: true } }
})

export default JobDetail
