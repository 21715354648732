import React, { FC } from 'react'
import Head from 'next/head'
// import { useTranslation } from 'react-i18next'
import { getPortalAndRegion } from '../../../core/utilities/PotalCodeMap'
// import { ENUM_JOB_STATUS } from '@freec/corelib/utilities/Enum'
// import env from 'utilities/env'
// import { LINK_URL_COMPANY_PUBLISH_JOBS } from 'utilities/URL'
import { employmentText } from '../../../core/utilities/common'
import dayjs from 'dayjs'
import ENV from '../../../env'
import { useRouter } from 'next/router'
import { JobPublicType } from '../job-detail.types'
import { uniqueAndJoinLocation } from '..'
import { Link } from '../../../link'
const GOOGLE_JOB_FORCE_TO_EXPIRED_DAY = 90
export const GoogleStructureTag: FC<{ jobDetail: JobPublicType }> = ({
  jobDetail
}) => {
  const { locale } = useRouter()
  const { postalCode, addressRegion } = getPortalAndRegion(
    uniqueAndJoinLocation(
      jobDetail?.job_locations.data?.map(
        (job_location: any) =>
          job_location.attributes.location.data.attributes.state
      )
    )
  )
  // const excludeTag = jobDetail?.status === ENUM_JOB_STATUS.closed
  const jobTag = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: jobDetail.title,
    description: jobDetail.description,
    identifier: {
      '@type': 'PropertyValue',
      name: jobDetail.company_name,
      value: jobDetail.company_slug
    },

    hiringOrganization: {
      '@type': 'Organization',
      name: jobDetail?.company_name,
      sameAs: `${ENV.PUBLIC_REMOTE_JOB_URL}${Link.LINK_URL_JOB_DETAILS(
        jobDetail.company_slug,
        jobDetail?.company_id
      )}`,
      logo: jobDetail?.company_logo
    },

    employmentType: locale
      ? employmentText(jobDetail.employment_type, locale)
      : '',
    datePosted: dayjs(jobDetail.create_at).format('YYYY-MM-DD'),
    ...(jobDetail.closed_date || jobDetail.expired_date
      ? {
          validThrough: dayjs(
            jobDetail.closed_date || jobDetail.expired_date
          ).format('YYYY-MM-DD')
        }
      : {
          validThrough: dayjs(jobDetail.create_at)
            .add(GOOGLE_JOB_FORCE_TO_EXPIRED_DAY, 'day')
            .format('YYYY-MM-DD')
        }),
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: jobDetail.address,
        addressLocality: uniqueAndJoinLocation(
          jobDetail?.job_locations.data?.map(
            (job_location: any) =>
              job_location.attributes.location.data.attributes.state
          )
        ),
        addressRegion,
        postalCode
      }
    },
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: jobDetail.salary_currency,
      value: {
        '@type': 'QuantitativeValue',
        minValue: jobDetail.min_salary,
        maxValue: jobDetail.max_salary,
        unitText: 'MONTH'
      }
    }
  }
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: `${JSON.stringify(jobTag)}` }}
      />
    </Head>
  )
}
