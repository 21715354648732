import { ObjectKeys } from 'react-hook-form/dist/types/path/common'
import { slugify } from './Slugify'

const pmap: { [key: string]: number | number[] } = {
  'An Giang': 880000,
  'Bắc Giang': 230000,
  'Bắc Kạn': 960000,
  'Bạc Liêu': 260000,
  'Bắc Ninh': 220000,
  'Bà Rịa–Vũng Tàu': 790000,
  'Bến Tre': 930000,
  'Bình Định': 590000,
  'Bình Dương': 820000,
  'Bình Phước': 830000,
  'Bình Thuận': 800000,
  'Cà Mau': 970000,
  'Cần Thơ': 900000,
  'Cao Bằng': 270000,
  'Da Nang': 550000,
  'Đắk Lắk': 630000,
  'Đắk Nông': 640000,
  'Điện Biên': 380000,
  'Đồng Nai': 710000,
  'Đồng Tháp': 870000,
  'Gia Lai': 600000,
  'Hà Giang': 310000,
  'Hà Nam': 400000,
  'Hà Tĩnh': 480000,
  'Hải Dương': 170000,
  'Hai Phong': 180000,
  'Ha noi': [100000, 150000],
  'Hậu Giang': 910000,
  'Hòa Bình': 350000,
  'Ho Chi Minh': [700000, 760000],
  'Hưng Yên': 160000,
  'Khánh Hòa': 650000,
  'Kiên Giang': 920000,
  'Kon Tum': 580000,
  'Lai Châu': 390000,
  'Lâm Đồng': 670000,
  'Lạng Sơn': 240000,
  'Lào Cai': 330000,
  'Long An': 850000,
  'Nam Định': 420000,
  'Nghệ An': [460000, 470000],
  'Ninh Bình': 430000,
  'Ninh Thuận': 660000,
  'Phú Thọ': 290000,
  'Phú Yên': 620000,
  'Quảng Bình': 510000,
  'Quảng Nam': 560000,
  'Quảng Ngãi': 570000,
  'Quảng Ninh': 200000,
  'Quảng Trị': 520000,
  'Sóc Trăng': 950000,
  'Sơn La': 360000,
  'Tây Ninh': 840000,
  'Thái Bình': 410000,
  'Thái Nguyên': 250000,
  'Thanh Hóa': [440000, 450000],
  'Thừa Thiên–Huế': 530000,
  'Tiền Giang': 860000,
  'Trà Vinh': 940000,
  'Tuyên Quang': 300000,
  'Vĩnh Long': 890000,
  'Vĩnh Phúc': 280000,
  'Yên Bái': 320000
}
const provinceRegionMap: { [key: string]: string } = {
  'Điện Biên': 'Northwest',
  'Hòa Bình': 'Northwest',
  'Lai Châu': 'Northwest',
  'Lào Cai': 'Northwest',
  'Sơn La': 'Northwest',
  'Yên Bái': 'Northwest',
  'Bắc Giang': 'Northeast',
  'Bắc Kạn': 'Northeast',
  'Cao Bằng': 'Northeast',
  'Hà Giang': 'Northeast',
  'Lạng Sơn': 'Northeast',
  'Phú Thọ': 'Northeast',
  'Quảng Ninh': 'Northeast',
  'Thái Nguyên': 'Northeast',
  'Tuyên Quang': 'Northeast',
  'Bắc Ninh': 'Red River Delta',
  'Hà Nam': 'Red River Delta',
  'Hà Nội': 'Red River Delta',
  'Hải Dương': 'Red River Delta',
  'Hải Phòng': 'Red River Delta',
  'Hưng Yên': 'Red River Delta',
  'Nam Định': 'Red River Delta',
  'Ninh Bình': 'Red River Delta',
  'Thái Bình': 'Red River Delta',
  'Vĩnh Phúc': 'Red River Delta',
  'Hà Tĩnh': 'North Central',
  'Nghệ An': 'North Central',
  'Quảng Bình': 'North Central',
  'Quảng Trị': 'North Central',
  'Thanh Hóa': 'North Central',
  'Thừa Thiên–Huế': 'North Central',
  'Bình Định': 'South Central Coast',
  'Bình Thuận': 'South Central Coast',
  'Đà Nẵng': 'South Central Coast',
  'Khánh Hòa': 'South Central Coast',
  'Ninh Thuận': 'South Central Coast',
  'Phú Yên': 'South Central Coast',
  'Quảng Nam': 'South Central Coast',
  'Quảng Ngãi': 'South Central Coast',
  'Đắc Lắc': 'Central Highlands',
  'Đắk Nông': 'Central Highlands',
  'Gia Lai': 'Central Highlands',
  'Kon Tum': 'Central Highlands',
  'Lâm Đồng': 'Central Highlands',
  'Bà Rịa–Vũng Tàu': 'Southeast',
  'Bình Dương': 'Southeast',
  'Bình Phước': 'Southeast',
  'Đồng Nai': 'Southeast',
  'Ho Chi Minh': 'Southeast',
  'Tây Ninh': 'Southeast',
  'An Giang': 'Mekong River Delta',
  'Bến Tre': 'Mekong River Delta',
  'Bạc Liêu': 'Mekong River Delta',
  'Cà Mau': 'Mekong River Delta',
  'Cần Thơ': 'Mekong River Delta',
  'Đồng Tháp': 'Mekong River Delta',
  'Hậu Giang': 'Mekong River Delta',
  'Kiên Giang': 'Mekong River Delta',
  'Long An': 'Mekong River Delta',
  'Sóc Trăng': 'Mekong River Delta',
  'Tiền Giang': 'Mekong River Delta',
  'Trà Vinh': 'Mekong River Delta',
  'Vĩnh Long': 'Mekong River Delta'
}
export const postalMap: { [key: string]: number | number[] } = Object.keys(
  pmap
).reduce((result, pr) => {
  const key = slugify(pr.toLowerCase())
  return { [key]: pmap[pr], ...result }
}, {})
export const regionMap: { [key: string]: string } = Object.keys(
  provinceRegionMap
).reduce((result, pr) => {
  const key = slugify(pr.toLowerCase())
  return { [key]: provinceRegionMap[pr], ...result }
}, {})

export const getPortalAndRegion = (areaName: string) => {
  if (!areaName) {
    return {
      postalCode: '',
      addressRegion: ''
    }
  }
  const postalCode = postalMap[slugify(areaName.toLowerCase())]
  return {
    postalCode: Array.isArray(postalCode) ? postalCode[0] : postalCode,
    addressRegion: regionMap[slugify(areaName.toLowerCase())]
  }
}
