import { FC } from 'react'
const LinkIcon: FC<{ color?: string; size?: number }> = ({
  color = '#6B7280',
  size = 16
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M10.068 3.669a1.6 1.6 0 0 1 2.263 2.262l-2.4 2.4a1.6 1.6 0 0 1-2.263 0 .8.8 0 1 0-1.131 1.132 3.2 3.2 0 0 0 4.525 0l2.4-2.4a3.2 3.2 0 1 0-4.525-4.526l-1.2 1.2A.8.8 0 1 0 8.868 4.87l1.2-1.2z"
        fill={color}
      />
      <path
        d="M6.068 7.669a1.6 1.6 0 0 1 2.263 0 .8.8 0 1 0 1.131-1.132 3.2 3.2 0 0 0-4.525 0l-2.4 2.4a3.2 3.2 0 1 0 4.525 4.526l1.2-1.2a.8.8 0 0 0-1.131-1.132l-1.2 1.2a1.6 1.6 0 1 1-2.263-2.262l2.4-2.4z"
        fill={color}
      />
    </svg>
  )
}

export default LinkIcon
