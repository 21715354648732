import { FC } from 'react'
const YoutubeSocialIcon: FC<{ color?: string; size?: number }> = ({
  color = 'red',
  size = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none">
      <g clipPath="url(#64mtbcvloa)">
        <path
          d="M9.631 3.333h.725c.305 0 .609.027.914.033a50.395 50.395 0 0 1 5.373.27c1.278.16 2 .845 2.24 2.11.168.99.259 1.991.27 2.996a26.212 26.212 0 0 1-.27 5.096c-.194 1.153-.882 1.865-2.038 2.037-.784.116-1.579.175-2.374.213a92.246 92.246 0 0 1-7.462.054 30.553 30.553 0 0 1-3.846-.27A2.247 2.247 0 0 1 1.22 14.17a5.723 5.723 0 0 1-.175-.935A27.816 27.816 0 0 1 .86 8.893c.009-1.084.108-2.166.297-3.234a2.34 2.34 0 0 1 1.886-1.946c.426-.08.856-.13 1.288-.153 1.48-.068 2.965-.114 4.444-.167l.857-.06zM8.19 7.028v5.487l4.76-2.749-4.76-2.738z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="64mtbcvloa">
          <path
            fill="#fff"
            transform="translate(.834 3.333)"
            d="M0 0h18.333v12.844H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default YoutubeSocialIcon
