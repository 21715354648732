import { FC } from 'react'
const FacebookSocialIcon: FC<{ color?: string; size?: number }> = ({
  color = '#1877F2',
  size = 20
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none">
      <g clipPath="url(#ogwocth79a)">
        <path
          d="M0 10.2v-.316c0-.341.05-.674.092-1.007A9.977 9.977 0 0 1 4.995 1.42a10.002 10.002 0 0 1 8.913-.563 9.899 9.899 0 0 1 4.924 4.454 9.883 9.883 0 0 1 .998 6.561 10.187 10.187 0 0 1-2.493 5.036 10.204 10.204 0 0 1-4.786 2.95L11.96 20v-7.188h1.94L14.342 10h-2.965V8.07a1.32 1.32 0 0 1 1.282-1.406c.616-.05 1.233 0 1.85-.042h.249V4.168a12.197 12.197 0 0 0-2.898-.175 3.433 3.433 0 0 0-3.265 3.27c-.058.832 0 1.664 0 2.496v.258H6.346v2.82h2.24v7.155h-.108a9.997 9.997 0 0 1-5.971-3.328 9.803 9.803 0 0 1-2.349-5.275C.067 11 .042 10.6 0 10.2z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="ogwocth79a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FacebookSocialIcon
