import { FC } from 'react'
const GroupUserIcon: FC<{ color?: string; size?: number }> = ({
  color = '#9ca3af',
  size = 16
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M7.2 4.8a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0zM13.6 4.8a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0zM10.344 13.6A5.576 5.576 0 0 0 9.2 9.335a4 4 0 0 1 6 3.465v.8h-4.856zM4.8 8.8a4 4 0 0 1 4 4v.8h-8v-.8a4 4 0 0 1 4-4z"
        fill={color}
      />
    </svg>
  )
}

export default GroupUserIcon
