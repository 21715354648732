import { FC } from 'react'
const UserIcon: FC<{ color?: string; size?: number }> = ({
  color = '#F87171',
  size = 24
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M12 10.8a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2zM3.6 21.6a8.4 8.4 0 1 1 16.8 0H3.6z"
        fill={color}
      />
    </svg>
  )
}

export default UserIcon
