import { FC } from 'react'
const GlobeIcon: FC<{ color?: string; size?: number }> = ({
  color = '#9ca3af',
  size = 16
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.266 7.2h1.557c.07-1.237.306-2.377.67-3.294A4.803 4.803 0 0 0 3.266 7.2zM8 1.6a6.4 6.4 0 1 0 0 12.8A6.4 6.4 0 0 0 8 1.6zm0 1.6c-.061 0-.186.025-.373.21-.19.187-.397.498-.59.945-.31.726-.537 1.713-.611 2.845h3.148C9.5 6.068 9.272 5.08 8.96 4.355c-.191-.447-.4-.758-.59-.946C8.186 3.225 8.062 3.2 8 3.2zm3.177 4c-.072-1.237-.307-2.377-.67-3.294A4.803 4.803 0 0 1 12.733 7.2h-1.556zM9.574 8.8H6.426c.074 1.132.3 2.12.612 2.845.192.447.4.758.59.946.186.184.31.209.372.209.06 0 .185-.025.372-.21.19-.187.398-.498.59-.945.31-.726.538-1.713.612-2.845zm.933 3.294c.363-.917.598-2.057.67-3.294h1.556a4.803 4.803 0 0 1-2.226 3.294zm-5.014 0c-.364-.917-.6-2.057-.67-3.294H3.266a4.803 4.803 0 0 0 2.227 3.294z"
        fill={color}
      />
    </svg>
  )
}

export default GlobeIcon
